import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title;
    const host = this.props.data.site.siteMetadata.host;
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} ${
      totalCount === 1 ? 'příspěvek označený' : (totalCount >= 2 || totalCount <= 4 ? 'příspěvky označené' : 'příspěvků označených')
    } štítkem “${tag}”`;
    const location = this.props.location;

    const metaTitle = `${tag} | Štítky | ${title}`;
    const metaUrl = `${host}${location.pathname || '/'}`;

    return (
      <Layout>
        <Helmet title={metaTitle}>
          <meta property="og:title" content={metaTitle}/>
          <meta property="og:url" content={metaUrl}/>
          <link rel="canonical" href={metaUrl} />
          <meta name="twitter:title" content={metaTitle} />
        </Helmet>
        <div className="main uk-width-auto section" id="main">
          <div className="widget Label" data-version="2" id="Label1">
            <div className="filtering_labels uk-grid uk-margin-bottom">
              <div className="breadcrumb uk-width-expand@m">
                <ul className="uk-breadcrumb">
                  <li><a href="/">Úvodní stránka</a></li>
                  <li><a href="/tags/">Štítky</a></li>
                  <li className="uk-disabled"><span>{tag}</span></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="widget Blog" data-version="2" id="Blog1">
            <p>{tagHeader}</p>
            <div
              className="blog_posts hfeed uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid uk-flex-top uk-flex-wrap-top uk-grid-stack"
              data-uk-grid="masonry:true" id="blog_posts">
              <ul className="taglist">{postLinks}</ul>
            </div>
            <div className='blog_pager uk-text-center uk-margin-large-top'>
              <Link className='blog-pager-older-link uk-button uk-button-default'
                    to='/tags/' title='Prohlédnout všechny štítky'>
                <span className='uk-margin-small-right'>Prohlédnout všechny štítky</span>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

/*
<article className="post hentry uk-first-column" data-id="1196696385671471000"
         style="transform: translateY(0px);">
  <div className="uk-card uk-card-hover uk-card-default">
    <div
      className="post_thumbnail uk-card-media-top uk-text-center uk-position-relative uk-transition-toggle"
      data-title="Post No.1 Containing a &quot;Youtube&quot; Video">
      <a href="https://salbuta-gradient-1.blogspot.com/2018/04/post-no1-containing-video.html">
        <img alt="Image" sizes="480px"
             src="https://lh4.googleusercontent.com/proxy/LyyJfIBBMzvHEFWHpvudPvBLSVP36Bc0uXSBhhOxeNP3Ov-kndycIqOWjeox_EWdW4ZzQOLAuKzAkjwTbfRUzyRFMBI"
             srcSet="https://lh4.googleusercontent.com/proxy/LyyJfIBBMzvHEFWHpvudPvBLSVP36Bc0uXSBhhOxeNP3Ov-kndycIqOWjeox_EWdW4ZzQOLAuKzAkjwTbfRUzyRFMBI=w480-h270-n-k-no-nu 480w">
          <div className="uk-position-cover uk-transition-fade uk-overlay-primary"></div>
          <div className="uk-position-center">
                      <span className="uk-transition-fade uk-icon" data-uk-icon="icon:play-circle;ratio:2.3"><svg
                        width="46" height="46" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        data-svg="play-circle"><polygon fill="none" stroke="#000" stroke-width="1.1"
                                                        points="8.5 7 13.5 10 8.5 13"></polygon><circle fill="none"
                                                                                                        stroke="#000"
                                                                                                        stroke-width="1.1"
                                                                                                        cx="10"
                                                                                                        cy="10"
                                                                                                        r="9"></circle></svg></span>
          </div>
      </a>
    </div>
    <div className="post_content uk-card-small uk-card-body">
      <div className="post_labels uk-panel uk-margin-small"><span
        className="uk-margin-small-right uk-icon" data-uk-icon="icon:tag;ratio:.7"><svg width="14"
                                                                                        height="14"
                                                                                        viewBox="0 0 20 20"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        data-svg="tag"><path
        fill="none" stroke="#000" stroke-width="1.1"
        d="M17.5,3.71 L17.5,7.72 C17.5,7.96 17.4,8.2 17.21,8.39 L8.39,17.2 C7.99,17.6 7.33,17.6 6.93,17.2 L2.8,13.07 C2.4,12.67 2.4,12.01 2.8,11.61 L11.61,2.8 C11.81,2.6 12.08,2.5 12.34,2.5 L16.19,2.5 C16.52,2.5 16.86,2.63 17.11,2.88 C17.35,3.11 17.48,3.4 17.5,3.71 L17.5,3.71 Z"></path><circle
        cx="14" cy="6" r="1"></circle></svg></span><a
        href="https://salbuta-gradient-1.blogspot.com/search/label/Nature" rel="tag">#Nature</a></div>
      <h2 className="post_title entry-title uk-margin">
        <a href="https://salbuta-gradient-1.blogspot.com/2018/04/post-no1-containing-video.html"
           rel="bookmark">Post No.1 Containing a "Youtube" Video</a>
      </h2>
      <div className="post_snippet entry-summary uk-position-relative">
        <p id="body1196696385671471000">Quisque at tellus nec magna viverra euismod. Maecenas lobortis,
          nisl et sodales blandit, neque mi ullamcorper eros, id efficitur ex velit in ante. Su... <a
            href="https://salbuta-gradient-1.blogspot.com/2018/04/post-no1-containing-video.html">Read
            more</a>
        </p>
      </div>
    </div>
  </div>
</article>
*/

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        host
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
